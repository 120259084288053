import * as React from 'react';
import { useEffect, useState } from 'react'
import AppBar from "./components/AppBar"
import Footer from "./components/Footer"
import CssBaseline from '@mui/material/CssBaseline';
import SessionContext from './components/SessionContext';
import {
  createBrowserRouter,
  RouterProvider,
  Link
} from "react-router-dom";
import './index.css';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Typography from '@mui/material/Typography';
import Main from './pages/main';
import ParkingPage from './pages/parking';
import IndoorMapPage from './pages/indoor_nav';
import ServicePage from './pages/service';
import Shorten from './pages/shorten_links';

export default function App() {
  const router = createBrowserRouter([
    {
      path: "",
      element: <PrivateRoute><Layout /></PrivateRoute>,
      handle: {
        // you can put whatever you want on a route handle
        // here we use "crumb" and return some elements,
        // this is what we'll render in the breadcrumbs
        // for this route
        crumb: () => <Typography variant="h4" color="textPrimary" component={Link} to="/">/</Typography>,
      },
      children: [
        {
          path: "",
          element: <Main />
        },
        {
          path: "401",
          element: <section><Typography variant="h4" color="textPrimary" component={Link} to="/">No Mirror Connected</Typography></section>,
        },
        {
          path: "event_images",
          children: [
            {
              path: ":serviceId",
              children: [
                {
                  path: ":servicelisting",
                  element: <ServicePage />
                }
              ]
            }
          ]
        },
        {
          path: ":remote",
          children: [
            {
              path: "parking",
              element: <ParkingPage />,
            },
            {
              path: "indoor",
              element: <IndoorMapPage />,
            }
          ]
          
        },
      ]
    },
    {
      path: "s",
      children: [{
        path: ":shortId",
        element: <Shorten />,
      }],
    }
  ]);

  

  return (
    <RouterProvider router={router}>
      
    </RouterProvider>
  );
}
